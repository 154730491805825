const initialState = () => ({
  offer: {},
  offers: [],
  offerKey: +new Date(),
});

export default {
  state: initialState(),
  actions: {
    async loadOffer({ commit, getters }, payload = {}) {
      if (payload.id === getters.getActiveOffer && !("_forceload" in payload)) return;
      let res = await this._vm.$api.get("Offer", payload);
      if (res.status === 200) {
        commit("setOffer", res.data.data);
      }
    },
    async confirmOffer({ dispatch }, payload = {}) {
      let res = await this._vm.$api.put("OfferConfirm", payload);
      if (res.status === 200 || res.status === 201) {
        dispatch("upsetOffersAction", res.data.data);
      }
      return res.status === 200 || res.status === 201;
    },
    async editOffer({ dispatch }, payload = {}) {
      let res = await this._vm.$api.put("Offer", payload);
      // commit("setOfferErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200 || res.status === 201) {
        dispatch("upsetOffersAction", res.data.data);
      }
      return res.status === 200 || res.status === 201;
    },

    async exportOffers({ getters }, payload = {}) {
      let res = await this._vm.$app.post("OfferExport", payload, { responseType: "blob" });
      if (res.status === 200) {
        let blob = new Blob([res.data], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = getters.getEnquiry.reference_id;
        link.click();
      }
      return res.status === 200;
    },
    // eslint-disable-next-line
    async sendEliteEmail({}, payload) {
      let res = await this._vm.$api.post("UpdateEliteMembership", payload);
      return res.status === 200;
    },

    addOffer({ dispatch, commit }, data) {
      dispatch("upsetOffersAction", data);
      commit("updateEnquiryOffersCount", data.enquiry_id);
    },
    upsetEnquiryOffersCount({ commit }, data) {
      commit("updateEnquiryOffersCount", data.enquiry_id);
    },

    upsetOffersAction({ state, commit }, data) {
      commit("upsetOffersMutation", data);
      if (state.offer.id === data.id) {
        commit("setOffer", data);
      }
      commit("setOfferKey", +new Date());
    },

    removeOffersAction({ state, commit }, data) {
      commit("removeOffer", data);
      commit("updateEnquiryOffersCountMinus", data.enquiry_id);
      if (state.offer.id === data.id) {
        commit("setOffer", {});
      }
      commit("setOfferKey", +new Date());
    },
  },
  mutations: {
    setOffer(state, data) {
      state.offer = data;
    },
    setOffers(state, data) {
      state.offers = data;
    },
    setOfferKey(state, data) {
      state.offerKey = data;
    },
    upsetOffersMutation(state, data) {
      const obj = state.offers.find((o) => o.id === data.id);
      obj ? Object.assign(obj, data) : state.offers.push(data);
    },
    removeOffer(state, data) {
      const index = state.offers.findIndex((o) => o.id === data.id);
      state.offers.splice(index, 1);
    },
    ResetOffer(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getOffer: (state) => state.offer,
    getOffers: (state) => state.offers,
    getOfferKey: (state) => state.offerKey,
    getActiveOffer: (state) => (state.offer.id ? state.offer.id : null),
  },
};
